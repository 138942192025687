import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FormattedMessage } from "react-intl";

const DownloadsPage = () => {
  const data = useStaticQuery(graphql`
    query DownloadQuery {
      allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            id
            base
            name
            publicURL
          }
        }
      }
    }
  `);
  return (
    <div className="box">
      <h4>
        <FormattedMessage id="pdf" />
      </h4>
      <ul>
        {data.allFile.edges.map((file, index) => {
          return (
            <li key={`pdf-${index}`}>
              <a href={file.node.publicURL} download>
                {file.node.name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default DownloadsPage;
